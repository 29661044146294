import HttpsRedirect from 'react-https-redirect';
//react router
import { BrowserRouter as Router, Route } from 'react-router-dom';

import './index.scss';
import { LoginPage } from './ui/pages/login_page/login_page';
import { AcceptTermsPage } from './ui/pages/accept_terms_page/accept_terms_page';
import { UserPage } from './ui/pages/user_page/user_page';
import { TasksPage } from './ui/pages/tasks_page/tasks_page';
import { ExportPage } from './ui/pages/export_page/export_page';
import { StudiesPage } from './ui/pages/studies_page/studies_page';
import { SelectionPage } from './ui/pages/selection_page/selection_page';
import { PreviewPage } from './ui/pages/preview_page/preview_page';
import { OverviewPage } from './ui/pages/overview_page/overview_page';
import { ReferencePage } from './ui/pages/reference_page/reference_page';
import { CycleTimingPage } from './ui/pages/cycletiming_page/cycletiming_page';
import { SegmentationPage } from './ui/pages/segmentation_page/segmentation_page';
import { MeasurementsPage } from './ui/pages/measurements_page/measurements_page';
import { ReviewPage } from './ui/pages/review_page/review_page';
import { PrismaReviewPage } from './ui/pages/prisma_pages/prisma_review_page';
import { PrismaDataPage } from './ui/pages/prisma_pages/prisma_data_page';
import { ValidationPage } from './ui/pages/validation_page/validation_page';
import { ThemeProvider } from '@material-ui/core/styles';
import { defaultTheme } from './themes'

function App() {
  return (
    <Router>
      <HttpsRedirect>
      <ThemeProvider theme={defaultTheme}>
        <div className="grid-block">
        {/* USER */}
        <Route exact path="/" render={(props) => <LoginPage {...props} />} />
        <Route exact path="/login" render={(props) => <LoginPage {...props} />} />
        <Route exact path="/accept-terms" render={(props) => <AcceptTermsPage {...props} />} />
        <Route exact path="/user-page" render={(props) => <UserPage {...props} />} />
        <Route exact path="/tasks" render={(props) => <TasksPage {...props} />} />

        {/* DATA SCIENTIST */}
        <Route exact path="/export" render={(props) => <ExportPage {...props} />} />

        {/* GROUND-TRUTH GENERATION */}
        <Route exact path="/studies" render={(props) => <StudiesPage {...props} />} />
        <Route exact path="/selection" render={(props) => <SelectionPage {...props} />} />
        <Route exact path="/preview" render={(props) => <PreviewPage {...props} />} />
        <Route exact path="/overview" render={(props) => <OverviewPage {...props} />} />
        <Route exact path="/reference" render={(props) => <ReferencePage {...props} />} />
        <Route exact path="/cycletiming" render={(props) => <CycleTimingPage {...props} />} />
        <Route exact path="/segmentation" render={(props) => <SegmentationPage {...props} />} />
        <Route exact path="/measurements" render={(props) => <MeasurementsPage {...props} />} />

        {/* OTHERS */}
        <Route exact path="/prisma-review" render={(props) => <PrismaReviewPage {...props} />} />
        <Route exact path="/prisma-data" render={(props) => <PrismaDataPage {...props} />} />

        {/* REVIEWER */}
        <Route exact path="/review" render={(props) => <ReviewPage {...props} />} />
        <Route exact path="/validation" render={(props) => <ValidationPage {...props} />} />
       
        </div>
      </ThemeProvider>
      </HttpsRedirect>
        
    </Router> 
  );
}

export default App;
